import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <section id="footer" className="bg-light pt-4 pb-2">
      <Container>{t('footer')}</Container>
    </section>
  );
}

export default Footer;
