import { Col, Container, Row } from 'react-bootstrap';
import { Check2Circle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

function About() {
  const { t } = useTranslation();

  return (
    <section id="about" className="pt-4 pb-4">
      <Container>
        <h3 className="display-3 text-center">{t('about.title')}</h3>
        <Row className="mt-3">
          <Col sm={12}>
            <h5 className="text-center display-5">{t('about.text')}</h5>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col sm={12} md={6}>
            <div className="circle mx-auto float-md-right mb-4" id="about-circled-image" />
          </Col>
          <Col sm={12} md={6}>
            <div className="d-flex flex-column justify-content-between info-blocks-wrapper">
              {t('about.items', { returnObjects: true })?.map((item, index) => (
                <div className="info-block d-flex align-items-center" key={index}>
                  <Check2Circle size={64} color="tomato" />
                  <div className="info-block-text ml-3">
                    <p>{item}</p>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default About;
