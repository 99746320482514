import { Suspense } from 'react';
import Header from './Header.jsx';
import About from './About.jsx';
import Costs from './Costs.jsx';
import Contacts from './Contacts.jsx';
import Footer from './Footer.jsx';
import Bar from './Bar';

function App() {
  return (
    <Suspense fallback="loading">
      <Bar />
      <Header />
      <About />
      <Costs />
      <Contacts />
      <Footer />
    </Suspense>
  );
}

export default App;
