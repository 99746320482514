import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import bg from '../images/3_thumb-1.jpg';

function Header() {
  const { t } = useTranslation();

  const scrollToElement = elementId =>
    window.scrollTo({
      top: document.getElementById(elementId).getBoundingClientRect().y,
      behavior: 'smooth'
    });

  return (
    <section className="bg-light" id="header">
      <Container>
        <Row>
          <Col sm={12} lg={6}>
            <div className="content">
              <h1>{t('header.title')}</h1>
              <h2>{t('header.subtitle')}</h2>
              <div>
                <Button variant="outline-primary" onClick={() => scrollToElement('costs')}>
                  {t('header.costs')}
                </Button>
                <Button variant="outline-success" onClick={() => scrollToElement('contacts')}>
                  {t('header.contact')}
                </Button>
              </div>
            </div>
          </Col>
          <Col sm={12} lg={6}>
            <div className="img-wrapper d-none d-lg-block">
              <img src={bg} alt="bg" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Header;
