import { useRef, useState } from 'react';
import { Alert, Badge, Button, Card, Col, Container, Form, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import HCaptcha from '@hcaptcha/react-hcaptcha';

function Map() {
  return (
    <iframe 
      src="https://yandex.ru/map-widget/v1/?um=constructor%3Af5c0477605da39f7daecbe8db23fe338c178ad240cefd7a6f4ba430d47160177&amp;source=constructor"
    />  
);
}

function Contacts() {
  const [status, setStatus] = useState(null);
  const [token, setToken] = useState(null);
  const captchaRef = useRef();

  const { t } = useTranslation();

  const sendForm = e => {
    e.preventDefault();

    const formData = new FormData(document.getElementById('feedback-form'));
    if (document.getElementsByName('name')[0].value === '')
      return document.getElementsByName('name')[0].classList.add('is-invalid');
    document.getElementsByName('name')[0].classList.remove('is-invalid');

    if (document.getElementsByName('feedbackAddress')[0].value === '')
      return document.getElementsByName('feedbackAddress')[0].classList.add('is-invalid');
    document.getElementsByName('feedbackAddress')[0].classList.remove('is-invalid');

    if (!token) return setStatus('TOKEN');

    formData.set('token', token.toString());

    setStatus('WAIT');
    axios
      .post('/submit', formData)
      .then(() => setStatus('SUCCESS'))
      .catch(() => setStatus('ERROR'));

    captchaRef.current.resetCaptcha();
  };

  return (
    <section id="contacts" className="bg-white pt-5 pb-5">
      <Container>
        <Row>
          <Col sm={12} md={4}>
            <Card className="mb-3 mb-md-0">
              <Card.Body className="text-center">
                <Card.Title>{t('contacts.title')}</Card.Title>
                <Card.Text>{t('contacts.address')}</Card.Text>
              </Card.Body>
              <ListGroup className="list-group-flush">
                {t('contacts.items', { returnObjects: true })?.map((value, index) => (
                  <ListGroupItem key={index}>
                    <Badge variant="primary">{value.label}</Badge>&nbsp;
                    {value.link ? <a href={value.link}>{value.text}</a> : value.text}
                  </ListGroupItem>
                ))}
              </ListGroup>
              <Card.Body>
                <Card.Title className="text-center">{t('contacts.form.title')}</Card.Title>
                <Form id="feedback-form" method="POST">
                  <Form.Group controlId="form-input-name">
                    <Form.Label>{t('contacts.form.name.label')}</Form.Label>
                    <Form.Control type="text" name="name" placeholder={t('contacts.form.name.placeholder')} />
                  </Form.Group>
                  <Form.Group controlId="form-input-name">
                    <Form.Label>{t('contacts.form.contact.label')}</Form.Label>
                    <Form.Control
                      type="text"
                      name="feedbackAddress"
                      placeholder={t('contacts.form.contact.placeholder')}
                    />
                  </Form.Group>
                  <Form.Group controlId="form-input-name">
                    <Form.Label>{t('contacts.form.language.label')}</Form.Label>
                    <Form.Control name="language" as="select" custom>
                      <option value="RU">{t('languages.ru')}</option>
                      <option value="BY">{t('languages.be')}</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="form-input-name">
                    <Form.Label>{t('contacts.form.message.label')}</Form.Label>
                    <Form.Control
                      name="message"
                      as="textarea"
                      rows={3}
                      placeholder={t('contacts.form.message.placeholder')}
                    />
                  </Form.Group>
                  <div style={{ height: 78, marginBottom: '1rem' }}>
                    <HCaptcha ref={captchaRef} sitekey={process.env.REACT_APP_HCAPTCHA_SITEKEY} onVerify={setToken} />
                  </div>
                  <Button variant="primary" type="submit" onClick={sendForm}>
                    {t('contacts.form.submit')}
                  </Button>
                </Form>
                {status === 'WAIT' ? (
                  <Alert variant="primary" className="mt-2">
                    {t('contacts.form.statuses.loading')}
                  </Alert>
                ) : status === 'SUCCESS' ? (
                  <Alert variant="success" className="mt-2">
                    {t('contacts.form.statuses.success')}
                  </Alert>
                ) : status === 'ERROR' ? (
                  <Alert variant="danger" className="mt-2">
                    {t('contacts.form.statuses.error')}
                  </Alert>
                ) : status === 'TOKEN' ? (
                  <Alert variant="danger" className="mt-2">
                    {t('contacts.form.statuses.token')}
                  </Alert>
                ) : null}
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={8}>
            <Map />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Contacts;
