import { Container, Form, FormControl, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

function Bar() {
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => i18n.changeLanguage(language), [language, i18n]);

  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="#home">{t('bar.title')}</Navbar.Brand>
        <Form inline>
          <FormControl as="select" custom value={language} onChange={event => setLanguage(event.target.value)}>
            <option value="ru">{t('languages.ru')}</option>
            <option value="be">{t('languages.be')}</option>
          </FormControl>
        </Form>
      </Container>
    </Navbar>
  );
}

export default Bar;
