import { Card, Col, Container, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Costs() {
  const { t } = useTranslation();

  return (
    <section id="costs" className="bg-light pt-3 pb-5">
      <Container>
        <h3 className="display-3 text-center mb-3">{t('costs.title')}</h3>
        <Row>
          {t('costs.items', { returnObjects: true })?.map((value, index) => (
            <Col sm={12} md={6} lg={3} key={index}>
              <Card className="text-center mb-3">
                <Card.Body>
                  <Card.Title>{value.title}</Card.Title>
                  <Card.Text>{value.cost}</Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush">
                  {value.items?.map((value1, index1) => (
                    <ListGroupItem key={index1}>{value1}</ListGroupItem>
                  ))}
                </ListGroup>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default Costs;
